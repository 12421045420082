import React, { useEffect } from 'react';
import {
    PhoneIcon,
    IdentificationIcon,
    BuildingStorefrontIcon,
    GlobeAltIcon,
    TicketIcon,
    UserIcon,
    CheckIcon
} from '@heroicons/react/24/outline';
import Wizard from '../../base/Wizard/Wizard';
import useNewClientOnboardingWizardStore from '../../../store/useNewClientOnboardingWizardStore';
import Introduction from './components/Introduction';
import NameInput from './components/NameInput';
import PhoneNumberInput from './components/PhoneNumberInput';
import AddressInput from './components/AddressInput';
import BirthdayDl from './components/BirthdayDl';
import PetInfo from './components/PetInfo';
import Confirmation from './components/Confirmation';
import { useParams } from 'react-router-dom';



export default function NewClientOnboardingWizard() {
  const { uuid } = useParams();
  const { currentStep, setNumSteps, loading, setUUID } = useNewClientOnboardingWizardStore(); // No need for the selector function here



    const wizardSteps = [
        { name: 'Greeting', icon: <BuildingStorefrontIcon /> },
        { name: 'Name', icon: <IdentificationIcon /> },
        { name: 'Phone Number Input', icon: <PhoneIcon /> },
        { name: 'Address Input', icon: <GlobeAltIcon /> },
        { name: 'Birthday and Drivers License', icon: <TicketIcon /> },
        { name: 'Pet Info', icon: <UserIcon /> },
        { name: 'Confirm Information and Sign', icon: <CheckIcon /> },
    ];



    useEffect(() => {
      // Access the current state directly for comparison
      const currentState = useNewClientOnboardingWizardStore.getState();
  
      if (uuid !== currentState.uuid) {
        setUUID(uuid);
      }
  
      if (wizardSteps.length !== currentState.numSteps) {
        setNumSteps(wizardSteps.length);
      }
    }, [uuid, setNumSteps, setUUID, wizardSteps]); // Include wizardSteps in the dependency array
  


    
  if (loading) {
      return <div>Loading...</div>;
  }



    return (
        <div>
            <Wizard currentStep={currentStep} steps={wizardSteps} loading={loading}>
                {currentStep === 0 && <Introduction />}
                {currentStep === 1 && <NameInput />}
                {currentStep === 2 && <PhoneNumberInput />}
                {currentStep === 3 && <AddressInput />}
                {currentStep === 4 && <BirthdayDl />}
                {currentStep === 5 && <PetInfo />}
                {currentStep === 6 && <Confirmation />}
            </Wizard>
        </div>
    );
}