import React, { useState } from 'react';
import Button from '../../../../base/Button';
import useNewClientOnboardingWizardStore from '../../../../../store/useNewClientOnboardingWizardStore';

export const questions = [
  { id: "question_1", label: "What are we seeing your pet for today?" },
  { id: "question_2", label: "When did the problem start?" },
  { id: "question_3", label: "Is the problem same, better or worse?" },
  { id: "question_4", label: "Has this problem happened in the past?" },
  { id: "question_5", label: "Are any medications being administered?" },
  { id: "question_6", label: "What is the pet's current diet and feeding schedule?" },
  { id: "question_7", label: "Eating Changes? Increased, Decreased, or No change" },
  { id: "question_8", label: "Any noticeable weight loss?" },
  { id: "question_9", label: "Any increase or decrease in water consumption?" },
  { id: "question_10", label: "Any changes in bowel movements?" },
  { id: "question_11", label: "Any other medical history or recent changes?" },
];

const QuestionModal = ({ question, closeModal }) => {
  const { formData, updateFormData } = useNewClientOnboardingWizardStore();
  const [inputValue, setInputValue] = useState(formData[question.id] || '');

  const handleOptionChange = (option) => {
    setInputValue(option);
    updateFormData({ [question.id]: option });
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setInputValue(value);
    updateFormData({ [question.id]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    closeModal();
  };

  const renderInput = () => {
    const optionsMap = {
      question_3: ["Same", "Better", "Worse"],
      question_4: ["Yes", "No"],
      question_7: ["Increase", "Decrease", "No Change"],
      question_8: ["Yes", "No"],
      question_10: ["Yes", "No"],
    };

    const options = optionsMap[question.id];
    if (options) {
      return (
        <div className="space-y-2">
          {options.map((option) => (
            <Button
              key={option}
              onClick={() => handleOptionChange(option)}
              variant={inputValue === option ? "primary" : "secondary"}
            >
              {option}
            </Button>
          ))}
        </div>
      );
    }

    return (
      <input
        type="text"
        value={inputValue}
        onChange={handleChange}
        className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
      />
    );
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <form onSubmit={handleSubmit} className="bg-white p-4 rounded-md shadow-lg space-y-4">
        <label className="block text-sm font-medium text-gray-700">{question.label}</label>
        {renderInput()}
        <div className="flex justify-end space-x-2">
          {/* <Button onClick={closeModal} variant="secondary">Cancel</Button> */}
          <Button onClick={closeModal} variant="primary">Save</Button>
        </div>
      </form>
    </div>
  );
};

export default QuestionModal;