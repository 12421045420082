import React, { useEffect, useMemo } from 'react';
import {
  IdentificationIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  ClipboardDocumentListIcon,
} from '@heroicons/react/24/outline';
import Wizard from '../../base/Wizard/Wizard';
import useNewClientOnboardingWizardStore from '../../../store/useNewClientOnboardingWizardStore';
import Introduction from './components/Introduction';
import { useParams } from 'react-router-dom';
import NameInput from './components/NameInput';
import IntakeForm from './components/IntakeForm';
import Confirmation from './components/Confirmation';


export default function PatientIntakeWizard() {
  const { uuid } = useParams();
  const { currentStep, setNumSteps, loading, setUUID } = useNewClientOnboardingWizardStore(); // Use patient intake store


  const wizardSteps = useMemo(() => [
    { name: 'Greeting', icon: <HomeIcon /> },
    { name: 'Name', icon: <IdentificationIcon /> },
    { name: 'IntakeForm', icon: <QuestionMarkCircleIcon /> },
    { name: 'Confirmation', icon: <ClipboardDocumentListIcon /> },
  ], []);

  useEffect(() => {
    const currentState = useNewClientOnboardingWizardStore.getState();

    if (uuid !== currentState.uuid) {
      setUUID(uuid);
    }

    if (wizardSteps.length !== currentState.numSteps) {
      setNumSteps(wizardSteps.length);
    }
  }, [uuid, setNumSteps, setUUID, wizardSteps]);




  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Wizard currentStep={currentStep} steps={wizardSteps} loading={loading}>
        {currentStep === 0 && <Introduction />}
        {currentStep === 1 && <NameInput />}
        {currentStep === 2 && <IntakeForm />}
        {currentStep === 3 && <Confirmation />}
      </Wizard>
    </div>
  );
}