import React, { useState, useEffect } from 'react';
import Button from '../../../base/Button';
import useNewClientOnboardingWizardStore from '../../../../store/useNewClientOnboardingWizardStore';
import { toast } from 'react-hot-toast';
import { submitPatientIntake } from '../../../../api/clientApi';
import OnSuccessModal from '../components/OnSuccessModal'

export default function Confirmation() {

  const { formData, prev, uuid, updateFormData } = useNewClientOnboardingWizardStore();



  
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleFinish = async (e) => {
    e.preventDefault();
    if (!isConfirmed) {
      toast.error("Please confirm that the information provided is correct.");
      return;
    }

    // Submit the data to the server
    try {
      const result = await submitPatientIntake(formData, uuid); 
      console.log(result);
      toast.success("Submitted");
      setShowSuccessModal(true);
    } catch (error) {
      console.error('Failed to submit:', error);
      toast.error("Missing information.");
    }
  };

  const handleCheckboxChange = (e) => {
    setIsConfirmed(e.target.checked);
  };

  const handleRecordRequest = () => {
    // Perform the record request logic or redirect as needed
  };

  return (
    <div className="flex flex-col items-center justify-center space-y-4 p-4">
      <h2 className="text-center text-2xl font-semibold mb-4">Confirm Your Information</h2>
      <div className="space-y-2">
        <p><strong>First Name:</strong> {formData.first_name}</p>
        <p><strong>Last Name:</strong> {formData.last_name}</p>
        <p><strong>Email:</strong> {formData.email}</p>
        <p><strong>What are we seeing your pet for today?</strong> {formData.question_1}</p>
        <p><strong>When did the problem start?</strong> {formData.question_2}</p>
        <p><strong>Is the problem same, better, or worse?</strong> {formData.question_3}</p>
        <p><strong>Has this problem happened in the past?</strong> {formData.question_4}</p>
        <p><strong>Are any medications being administered?</strong> {formData.question_5}</p>
        <p><strong>What is the pet's current diet and feeding schedule?</strong> {formData.question_6}</p>
        <p><strong>Eating Changes? Increased, Decreased, or No change:</strong> {formData.question_7}</p>
        <p><strong>Any noticeable weight loss?</strong> {formData.question_8}</p>
        <p><strong>Any increase or decrease in water consumption?</strong> {formData.question_9}</p>
        <p><strong>Any changes in bowel movements?</strong> {formData.question_10}</p>
        <p><strong>Any other medical history or recent changes?</strong> {formData.question_11}</p>
      </div>
      <label className="inline-flex items-center mt-3">
        <input 
          type="checkbox" 
          className="form-checkbox h-5 w-5 text-gray-600" 
          checked={isConfirmed} 
          onChange={handleCheckboxChange} 
        />
        <span className="ml-2 text-gray-700">
          I confirm that the information provided is correct and complete.
        </span>
      </label>
      <div className="fixed p-4 inset-x-0 bottom-0 flex w-full justify-between px-4">
        <Button onClick={prev} variant="secondary">Back</Button>
        <Button onClick={handleFinish} variant="primary" disabled={!isConfirmed}>
          Confirm
        </Button>
      </div>
      <OnSuccessModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        onRecordRequest={handleRecordRequest}

      />
    </div>
  );
}
